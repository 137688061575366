import { http } from '@/services'

export const checkoutHelpers = {
  methods: {
    saveCheckoutOptions() {
      var data = {
        package: this.$store.getters.checkoutBasket.package,
        printing: this.$store.getters.checkoutBasket.printing
      }
      http
        .patch('/wills/api/checkout_basket', data)
        .then((response) => {
          this.$store.commit('checkoutBasket', response.data.checkout_basket)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}

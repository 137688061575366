<template>
  <NavQuestion
    v-bind:heading="'Setup your Direct Debit '"
    v-bind:background="'bg-basket'"
    v-bind:number="questionNumber"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <div class="card-form">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 mb30">
            <h5 class="heading">Billing Details</h5>
          </div>
        </div>

          <div v-if="oneOffPaymentNotification" class="notification">
            {{ oneOffPaymentNotification }}
          </div>


        <div class="row mt30">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.firstName"
                type="text"
                id="first"
                class="input-standard-grey validation"
                :class="{ error: error.firstName }"
                required
                placeholder="First Name"
              />
              <label for="first" :class="{ 'error-text': error.firstName }">{{
                error.firstName || 'First Name'
              }}</label>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.lastName"
                type="text"
                id="last"
                class="input-standard-grey width-full validation"
                :class="{ error: error.lastName }"
                required
                placeholder="Last Name"
              />
              <label for="last" :class="{ 'error-text': error.lastName }">{{
                error.lastName || 'Last Name'
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.email"
                type="text"
                id="email"
                class="input-standard-grey validation"
                :class="{ error: error.email }"
                required
                placeholder="Email"
              />
              <label for="email" :class="{ 'error-text': error.email }">{{
                error.email || 'Email'
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.billingAddressLine1"
                type="text"
                id="address1"
                class="input-standard-grey validation"
                :class="{ error: error.billingAddressLine1 }"
                required
                placeholder="Billing Address 1"
              />
              <label
                for="address1"
                :class="{
                  'error-text': error.billingAddressLine1
                }"
                >{{ error.billingAddressLine1 || 'Billing Address 1' }}</label
              >
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.townCity"
                type="text"
                id="town"
                class="input-standard-grey validation"
                :class="{ error: error.townCity }"
                required
                placeholder="Town/City"
              />
              <label for="town" :class="{ 'error-text': error.townCity }">{{
                error.townCity || 'Town/City'
              }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.postCode"
                type="text"
                id="post-code"
                class="input-standard-grey width-full validation"
                :class="{ error: error.postCode }"
                required
                placeholder="Post Code"
              />
              <label
                for="post-code"
                :class="{ 'error-text': error.postCode }"
                >{{ error.postCode || 'PostCode' }}</label
              >
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12"></div>
        </div>

        <div class="row bank-details mt30">
          <div class="col-lg-12 col-md-12 col-sm-12 mb30">
            <h5 class="heading">Bank Details</h5>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.sortCode"
                type="text"
                id="sort"
                class="input-standard-white validation"
                :class="{ error: error.sortCode }"
                required
                placeholder="Sort Code"
              />
              <label for="sort" :class="{ 'error-text': error.sortCode }">{{
                error.sortCode || 'SortCode'
              }}</label>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="input-container">
              <input
                v-model="form.accountNumber"
                type="text"
                id="account-number"
                class="input-standard-white width-full validation"
                :class="{ error: error.accountNumber }"
                required
                placeholder="Account Number"
              />
              <label
                for="account-number"
                :class="{ 'error-text': error.accountNumber }"
                >{{ error.accountNumber || 'Account Number' }}</label
              >
            </div>
          </div>
        </div>

        <div class="row mt30">
          <div class="col-lg-11 col-md-11 col-sm-11 col-11">
            <p class="check">
              I confirm that I am the account holder and am authorised to set up
              Direct Debit payments on this account.
            </p>
          </div>
          <div class="col-lg-1 col-md-1 col-sm-1 col-1">
            <div id="terms-label" class="css-checkbox light inline-block">
              <input
                v-model="form.multipleAuthorise"
                type="checkbox"
                id="authorised"
              />
              <label for="authorised"><span></span></label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="validatePage"
        v-bind:forwardText="'Next'"
        v-bind:backLink="backTo"
        v-bind:loading="loading"
        v-bind:forwardDisabled="loading"
        @forwardClick="createCustomer"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import { checkoutHelpers } from '@/common/mixins/checkoutHelpers'
import { TextFormat } from '@/common/mixins/TextFormat'
import { http } from '@/services'

export default {
  name: 'DirectDebitSetupPage',
  mixins: [checkoutHelpers, TextFormat],
  components: {
    NavQuestionButtons,
    NavQuestion
  },
  mounted() {
    if (!this.$store.getters.willPreview) {
      this.$store.dispatch('willPreviewFetch')
    }
    if (this.$store.getters.directDebitForm) {
      this.form = { ...this.$store.getters.directDebitForm }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveCheckoutOptions()
    next()
  },
  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        country: 'GB',
        sortCode: null,
        accountNumber: null,
        billingAddressLine1: null,
        townCity: null,
        postCode: null,
        multipleAuthorise: false
      },
      error: {
        firstName: null,
        lastName: null,
        email: null,
        sortCode: null,
        accountNumber: null,
        billingAddressLine1: null,
        townCity: null,
        postCode: null
      },
      loading: false
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(form) {
        if (!form.accountNumber) this.error.accountNumber = null
        if (!form.sortCode) this.error.sortCode = null
        if (!form.postCode) this.error.postCode = null
        if (!form.email) this.error.email = null
      }
    },
    personalDetails: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.$store.getters.directDebitForm) {
          this.form.billingAddressLine1 = value.address_line_1
          this.form.townCity = value.post_town
          this.form.postCode = value.post_code
          if (this.user) this.form.email = this.user.email
          this.form.firstName = this.firstWord(value.full_name, false, false)
          this.form.lastName = this.lastWord(value.full_name, false, false)
        }
      }
    }
  },
  computed: {
    forwardTo() {
      return '/pay/direct_debit_confirmation'
    },
    backTo() {
      return '/pay'
    },
    validatePage() {
      if (
        this.form.firstName &&
        this.form.lastName &&
        this.form.email &&
        this.form.country &&
        this.form.billingAddressLine1 &&
        this.form.postCode &&
        this.form.townCity &&
        this.form.sortCode &&
        this.form.accountNumber &&
        this.form.multipleAuthorise
      ) {
        return true
      }
      return false
    },
    questionNumber() {
      return '5'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    user() {
      return this.$store.getters.user
    },
    prices() {
      return this.$store.getters.prices
    },
    basket() {
      return this.$store.getters.checkoutBasket
    },
    oneOffPaymentNotification() {
      if (this.basket.charge_now_price && !this.basket.package) {
        var string = ''
        if (
            this.basket.printing &&
            this.basket.charge_now_price === this.prices.printing
        )
          string +=
              'Printing is securely charged by Direct Debit provided by GoCardless. '
        else if (
            this.basket.printing &&
            this.basket.charge_now_price !== this.prices.printing
        )
          string +=
              'Wills and printing are securely charged by Direct Debit provided by GoCardless.'
        else if (!this.basket.printing)
          string +=
              'Wills are securely charged by Direct Debit provided by GoCardless.'
        string += ' This is a SINGLE payment, no further debits will be taken.'
        return string
      }
      return false
    },
  },

  methods: {
    createCustomer() {
      if (this.validatePage) {
        this.loading = true
        http
          .post('/wills/api/direct_debit_setup_customer_bank', this.form)
          .then((response) => {
            this.$store.commit('directDebitForm', this.form)
            this.$store.commit('bankAccountSetup', response.data)
            this.$router
              .push('/pay/direct_debit_confirmation')
              .catch((error) => {
                console.log(error)
              })
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.handleErrors(error.response.data)
          })
      }
    },
    handleErrors(error) {
      this.clearErrors()
      if (error.errors) {
        var errors = error.errors
        if (errors && Array.isArray(errors)) {
          for (var i = 0; i < errors.length; i++) {
            if (errors[i].field === 'account_number')
              this.error.accountNumber = `Account Number ${errors[i].message}`
            if (errors[i].field === 'branch_code')
              this.error.sortCode = `Sort Code ${errors[i].message}`
            if (errors[i].field === 'postal_code')
              this.error.postCode = `Post Code ${errors[i].message}`
            if (errors[i].field === 'email')
              this.error.email = `Email ${errors[i].message}`
          }
        } else {
          this.$notification.error(errors)
        }
      } else {
        this.$notification.error(error)
      }
    },
    clearErrors() {
      this.error.accountNumber = null
      this.error.firstName = null
      this.error.lastName = null
      this.error.email = null
      this.error.sortCode = null
      this.error.accountNumber = null
      this.error.billingAddressLine1 = null
      this.error.townCity = null
      this.error.postCode = null
    }
  }
}
</script>

<style scoped>
.width-full {
  max-width: 100%;
}

.check {
  display: inline-block;
  font-size: 15px;
  margin-left: 10px;
}

.error-text {
  font-size: 15px;
  color: #e56781;
  white-space: nowrap;
}

.error {
  color: #e56781;
  border-top: 1px solid #e56781 !important;
  border-left: 1px solid #e56781 !important;
  border-bottom: 1px solid #e56781 !important;
  border-right: 5px solid #e56781 !important;
}

.card-form {
  border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 45px 0px rgba(0, 0, 0, 0.1);
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 60px;
  background-color: white;
}
.bank-details {
  background-color: beige;
  padding-top: 30px;
  border-radius: 10px;
}
@media screen and (max-width: 570px) {
  .card-form {
    padding: 10px;
    border-radius: 0;
  }
  .bank-details {
    padding: 10px;
    border-radius: 0;
  }
}
.heading {
  font-family: 'Vollkorn', serif;
  line-height: 1.3;
  margin: 0;
  font-weight: 600;
  color: #2f2c2c;
}

.notification {
  font-size: 20px;
  padding: 20px;
  background-color: #e56781;
  color: white;
  border-radius: 10px;
  margin-bottom: 50px;
}
</style>
